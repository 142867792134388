import React from 'react'
import './CardItem.scss'
import { Link } from 'gatsby'
import weekdayArray from '../../shared/Constants/WeekdayArray'

export default ({ square, imgUrl, heading, time, weekdays, linkTo, extraHeading, area, description }) => {
  if (linkTo) {
    return (
      <Link to={linkTo} className="card_item col">
        <div className={`card_image_container ${square ? 'square' : ''}`}>
          <img alt="" className="card_image" src={imgUrl} />
        </div>
        <div className="card_text_container">
          {
            extraHeading && <span className="card_subheader card_extraheading">{extraHeading}</span>
          }
          <span className="card_subheader">{area}</span>
          <h5 className="card_heading">{heading}</h5>
          <p className="card_description">{description}</p>
          <hr />
          <span className="card_subheader">{time}</span>
          {weekdays && weekdays.split(',').map((day, i) =>
            <span key={i} className='card_weekdays'>{weekdayArray[day - 1]}</span>
          )}
        </div>
      </Link>
    )
  } else {
    return (
      <div className="card_item col">
        <div className={`card_image_container ${square ? 'square' : ''}`}>
          <img alt="" className="card_image" src={imgUrl} />
        </div>
        <div className="card_text_container">
          {
            extraHeading && <span className="card_subheader card_extraheading">{extraHeading}</span>
          }
          <span className="card_subheader">{time}</span>
          <h5 className="card_heading">{heading}</h5>
        </div>
      </div>
    )
  }
}
