import React from 'react'
import SEO from '../../components/seo'
import '../../styles/index.scss'
import PageWrapper from '../../shared/PageWrapper/PageWrapper'
import { RenderModules } from '../../blocks/renderer'

const customPage = props => {
  const seo = {
    title: props.pageContext.seo.title ? props.pageContext.seo.title : null,
    image: props.pageContext.seo.image ? props.pageContext.seo.image : null,
    description: props.pageContext.seo.description && props.pageContext.seo.description.description ? props.pageContext.seo.description.description : null,
  }
  return (
    <>
      <SEO {...seo} />
      <PageWrapper hideNav={!props.pageContext.page.showBottomNavigation} state={props.location.state}>
        <RenderModules location={props.location} modules={props.pageContext.page.contentModules} />
      </PageWrapper>
    </>
  )
}

export default customPage
