import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Section from '../../shared/Section/Section'
import BigText from '../../shared/BigText/BigText'
import Text from '../../shared/Text/Text'
import Slider from '../../shared/Slider/Slider'
import CardItem from '../../shared/Card/CardItem'

export default ({ sectionTitle, sectionDescription }) => (
  <StaticQuery
    query={graphql`
    query {
      allOffers(limit: 5) {
        edges {
          node {
            active
            area
            id
            image
            link
            link_text
            phone
            text
            text_larger
            tilbod
            time
            title
            website {
              website_name
            }
            weekdays
            what {
              title
            }
            when {
              title
            }
            where {
              location
            }
          }
        }
      }
    }
    `}
    render={data => {
      return (
        <Section small>
          <BigText text={sectionTitle} />
          <div className="rich_text_container">
            {sectionDescription}
          </div>
          <Slider>
            {
              data.allOffers.edges.map((offerItem, index) => <CardItem key={`/tilbod/${offerItem.node.id}`} linkTo={`/tilbod/${offerItem.node.tilbod}`} imgUrl={offerItem.node.image} subheading={offerItem.node.time} heading={offerItem.node.title} />)
            }
          </Slider>
        </Section>
      )
    }}
  />
)
