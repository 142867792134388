import React, { useState, useEffect } from 'react'
import axios from 'axios'
import InputItem from '../../shared/InputItem/Input'
import BigText from '../../shared/BigText/BigText'
import Text from '../../shared/Text/Text'
import SemiText from '../../shared/SemiText/SemiText'
import './Abroad.scss'
import { FilteredCountryItem } from '../../shared/FilteredCountryItem/FilteredCountryItem'
import { findMatchingCountry } from './findMatchingCountry'

const fetchSingleCountry = async countryCode => {
  const country = await axios.get(`https://api.sambandid.is/open/roaming/cost/${countryCode}`)
  return country
}

const Abroad = props => {
  const [inputVal, setInputVal] = useState('')
  const [filteredCountries, setFilteredCountries] = useState([])
  const [activeCountry, setActiveCountry] = useState(null)
  const [seePricing, setSeePricing] = useState(false)
  const { countries } = props
  let timeout = null

  useEffect(() => {
    if (inputVal.length === 0) {
      setFilteredCountries([])
    }

    if (countries && !(inputVal.length === 0)) {
      timeout = setTimeout(() => {
        const filteredNations = findMatchingCountry(inputVal, countries)
        setFilteredCountries(filteredNations)
      }, 1000)
    }

    return () => clearTimeout(timeout)
  }, [inputVal, setInputVal])

  useEffect(() => {
    // ?country=belgium
    const urlParams = new URLSearchParams(props.location.search)
    const paramCountry = urlParams.get('country')
    if (paramCountry) {
      const filteredNations = findMatchingCountry(paramCountry, countries)
      if (filteredNations.length === 1) {
        setActiveCountry(filteredNations[0])
      }
    }
  }, [])

  const onKeyUp = e => {
    e.preventDefault()
    setActiveCountry(null)
    setInputVal(e.target.value)
  }

  const getCountryRoamingInfo = countryCode => {
    fetchSingleCountry(countryCode).then(res => setActiveCountry(res.data))
  }

  const getOutsideEESText = travelPackageAvailable => {
    if (travelPackageAvailable) {
      return (
        <div>
          <p>Ferðapakkinn er í boði í þessu landi</p>
          {props.outsideEESTextWithTravelPackage}
        </div>
      )
    } else {
      return <div>{props.outsideEESTextWithoutTravelPackage}</div>
    }
  }

  return (
    <div className="abroad_container">
      <BigText text={props.sectionTitle} />
      <div className="rich_text_container">
        {props.sectionDescription}
      </div>
      <form onSubmit={e => e.preventDefault()} autoComplete="off">
        <InputItem value={activeCountry ? activeCountry.country.name_is : inputVal} labelText={props.inputLabel} placeholder={props.inputPlaceholder} uniqueId="hringtTilUtlanda" onChange={onKeyUp}/>
      </form>
      {
        activeCountry
          ? null
          : <div className="" style={{ maxHeight: '450px', overflowY: 'scroll' }}>
            { filteredCountries.map((item, index) => <FilteredCountryItem key={item.code2} onClick={() => getCountryRoamingInfo(item.code2)} text={`${item.name_is} (${item.name})`} />) }
          </div>
      }
      <div>
        {
          activeCountry
            ? <div style={{ marginTop: '30px' }}>
              <div className="rich_text_container">
                {activeCountry.country.roam_like_home ? <Text text={`${activeCountry.country.name_is} er land innan EES`} /> : <></>}
                {activeCountry.country.roam_like_home ? props.insideEESText : getOutsideEESText(activeCountry.country.travel_package_available)}
              </div>
              <div style={{ marginTop: '50px' }} />
              {
                activeCountry.country.travel_package_available
                  ? <button className="show_pricing" onClick={() => setSeePricing(!seePricing)}>{activeCountry.country.roam_like_home ? 'Skoða almenna verðskrá' : activeCountry.country.travel_package_available ? 'Skoða verðskrá án ferðapakkans' : 'Skoða verðskrá án krónuinneignar'}</button>
                  : null
              }
              <div className={`pricing_list ${seePricing || !activeCountry.country.travel_package_available ? 'active' : ''}`}>
                {
                  !activeCountry.country.roam_like_home
                    ? <>
                    <SemiText text="Almenn verðskrá" />
                    <ul className="abroad_price_list_container">
                      {
                        activeCountry.country.prices_voice && activeCountry.country.prices_voice.country &&
                        <li className="abroad_price_list_item">
                          <span>Upphafsgjald</span>
                          <span>{activeCountry.country.prices_voice.activate_price}</span>
                        </li>
                      }
                      {
                        activeCountry.country.prices_voice && activeCountry.country.prices_voice.country &&
                        <li className="abroad_price_list_item">
                          <span>Sama land (pr/mín.)</span>
                          <span>{activeCountry.country.prices_voice.country}</span>
                        </li>
                      }
                      {
                        activeCountry.country.prices_voice && activeCountry.country.prices_voice.to_iceland &&
                        <li className="abroad_price_list_item">
                          <span>Til Íslands og landa innan EU (pr/mín.)</span>
                          <span>{activeCountry.country.prices_voice.to_iceland}</span>
                        </li>
                      }
                      {
                        activeCountry.country.prices_voice && activeCountry.country.prices_voice.to_other &&
                        <li className="abroad_price_list_item">
                          <span>Til annarra landa (pr/mín.)</span>
                          <span>{activeCountry.country.prices_voice.to_other}</span>
                        </li>
                      }
                      {
                        activeCountry.country.prices_voice && activeCountry.country.prices_voice.received_call &&
                        <li className="abroad_price_list_item">
                          <span>Móttekið símtal (pr/mín.)</span>
                          <span>{activeCountry.country.prices_voice.received_call}</span>
                        </li>
                      }
                      {
                        activeCountry.country.prices_voice && activeCountry.country.prices_voice.sms &&
                        <li className="abroad_price_list_item">
                          <span>SMS (stk) Upplýsingar um SMS með íslenskum stöfum</span>
                          <span>{activeCountry.country.prices_voice.sms}</span>
                        </li>
                      }
                      {
                        activeCountry.country.prices_voice && activeCountry.country.prices_voice.time_measurement &&
                        <li className="abroad_price_list_item">
                          <span>Mælieining</span>
                          <span>{activeCountry.country.prices_voice.time_measurement}</span>
                        </li>
                      }
                      {
                        activeCountry.country.prices_gprs && activeCountry.country.prices_gprs.gprs &&
                        <li className="abroad_price_list_item">
                          <span>Netið í símann (pr/MB)</span>
                          <span>{activeCountry.country.prices_gprs.gprs}</span>
                        </li>
                      }
                    </ul>
                    </>
                    : null
                }

              </div>
            </div>
            : null
        }
      </div>
    </div>
  )
}

export default Abroad

const insideEESText = () => {

}
