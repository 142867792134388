import React from 'react'
import './Slider.scss'

export default ({ children, noSlider }) => (
  <div className={`slider`}>
    <div className={`row slider_content${noSlider ? ' disabled' : ''}`}>
      {children}
    </div>
  </div>
)
