import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Section from '../../shared/Section/Section'
import BigText from '../../shared/BigText/BigText'
import Slider from '../../shared/Slider/Slider'
import CardItem from '../../shared/Card/CardItem'

export default ({ sectionTitle, sectionDescription }) => (
  <StaticQuery
    query={graphql`
    query {
      allOffers {
        edges {
          node {
            active
            area
            id
            image
            link
            link_text
            phone
            text
            text_larger
            tilbod
            time
            title
            website {
              website_name
            }
            weekdays
            what {
              title
            }
            when {
              title
            }
            where {
              location
            }
          }
        }
      }
    }
    `}
    render={data => {
      return (
        <Section small>
          <div style={{ marginTop: '10vh' }}>
            <BigText text={sectionTitle} />
            <div className="rich_text_container">
              {sectionDescription}
            </div>
            <Slider noSlider>
              {
                data.allOffers.edges.map((offerItem, i) =>
                  <CardItem linkTo={`/tilbod/${offerItem.node.tilbod}`}
                    key={i}
                    imgUrl={offerItem.node.image}
                    time={offerItem.node.time}
                    heading={offerItem.node.title}
                    area={offerItem.node.area}
                    description={offerItem.node.text_larger}
                    weekdays={offerItem.node.weekdays} />)
              }
            </Slider>
          </div>
        </Section>
      )
    }}
  />
)
