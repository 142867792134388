import React from 'react'
import FAQItem from './FAQItem.js'
import BigText from '../../shared/BigText/BigText'
import Section from '../../shared/Section/Section'

const faq = [
  {
    question: 'sadflkasndf laknsdflk nasdlfkn asdlkfb asilefkja sekflasef',
    answer: 'jfaslkdfhaegioufa answer answer answer'
  },
  {
    question: 'sadflkasndf laknsdflk nasdlfkn asdlkfb asilefkja sekflasef',
    answer: 'jfaslkdfhaegioufa answer answer answer'
  },
  {
    question: 'sadflkasndf laknsdflk nasdlfkn asdlkfb asilefkja sekflasef',
    answer: 'jfaslkdfhaegioufa answer answer answer'
  },
  {
    question: 'sadflkasndf laknsdflk nasdlfkn asdlkfb asilefkja sekflasef',
    answer: 'jfaslkdfhaegioufa answer answer answer'
  },
  {
    question: 'sadflkasndf laknsdflk nasdlfkn asdlkfb asilefkja sekflasef',
    answer: 'jfaslkdfhaegioufa answer answer answer'
  },
]

export default ({
  sectionTitle,
  sectionDescription,
  faqItems
}) => (
  <Section full>
    <div className="faq_container">
      <BigText text={sectionTitle} />
      <div className="rich_text_container">
        {sectionDescription}
      </div>
      <div className="faq_items_container">
        {
          faqItems.map((faqItem, index) => <FAQItem item={faqItem} key={index} />)
        }
      </div>
    </div>
  </Section>
)
