import React from 'react'
import s from './BigText.scss'

export default props => {
  return (
    <h2 className={'big_text'}>
      {props.text}
    </h2>
  )
}
