import React from 'react'
import Layout from '../Layout/layout'
import './Section.scss'

export default ({ children, small, full, backgroundImage, centered }) => {
  if (backgroundImage) {
    return (
      <section style={{backgroundImage: `url(${backgroundImage})`, border: 'none' }} className={`${full ? 'full ' : ''}${small ? 'small-section ' : ''}${centered ? 'centered ' : ''}`}>
        <Layout styles={{ height: '100%', zIndex: 10 }}>
          {children}
        </Layout>
      </section>
    )
  } else {
    return (
      <section className={`${full ? 'full ' : ''}${small ? 'small-section ' : ''}${centered ? 'centered ' : ''}`}>
        <Layout styles={{ height: '100%' }}>
          {children}
        </Layout>
      </section>
    )
  }
}
