import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import {
  ImageRichText,
  BackgroundImageWithText,
  BackgroundVideoWithText,
  Packets,
  Events,
  Offers,
  RichText,
  Abroad,
  Outwards,
  FAQ,
  AllOffers,
  SimCardForm,
  LandingPage
} from '.'
import { options } from './options'

export const RenderModules = ({ location, modules }) => {
  return (
    <>
      {modules.map((m, i) => {
        switch (m.internal.type) {
          case 'ContentfulPageSectionWithBackgroundVideo':
            return (
              <BackgroundVideoWithText
                videoUrl={m.backgroundVideo.file.url}
                sectionTitle={m.sectionTitle}
                sectionDescription={documentToReactComponents(
                  m.sectionDescription.json
                )}
                key={m.id}
              />
            )
          case 'ContentfulPageSectionWithBlackBackground':
            const richText = documentToReactComponents(
              m.sectionDescription.json,
              options
            )
            return (
              <ImageRichText
                key={m.id}
                sectionTitle={m.sectionTitle}
                sectionDescription={richText}
                showAppImage={m.showAppImage}
                appImage={m.appImage ? m.appImage.fluid.src : ''}
              />
            )
          case 'ContentfulPageSectionWithBackgroundImage':
            return (
              <BackgroundImageWithText
                key={m.id}
                desktopBackgroundImage={
                  m.desktopBackgroundImage
                    ? m.desktopBackgroundImage.fluid.src
                    : ''
                }
                mobileBackgroundImage={
                  m.mobileBackgroundImage
                    ? m.mobileBackgroundImage.fluid.src
                    : ''
                }
                backgroundImage={
                  m.backgroundImage ? m.backgroundImage.fluid.src : ''
                }
                sectionTitle={m.sectionTitle}
                sectionDescription={documentToReactComponents(
                  m.sectionDescription.json,
                  options
                )}
                showAppImage={m.showAppScreenshot}
              />
            )
          case 'ContentfulPageSectionWithOfferList':
            return (
              <Offers
                key={m.id}
                sectionTitle={m.sectionTitle}
                cardsLimit={m.cardsLimit}
                sectionDescription={documentToReactComponents(
                  m.sectionDescription.json,
                  options
                )}
              />
            )
          case 'ContentfulPageSectionWithAllOfferList':
            return (
              <AllOffers
                key={m.id}
                sectionTitle={m.sectionTitle}
                sectionDescription={documentToReactComponents(
                  m.sectionDescription.json,
                  options
                )}
              />
            )
          case 'ContentfulPageEventsList':
            return (
              <Events
                key={m.id}
                sectionTitle={m.sectionTitle}
                sectionDescription={documentToReactComponents(
                  m.sectionDescription.json,
                  options
                )}
              />
            )
          case 'ContentfulPageSectionWithPackagesList':
            return (
              <Packets
                key={m.id}
                sectionTitle={m.sectionTitle}
                sectionDescription={documentToReactComponents(
                  m.sectionDescription.json,
                  options
                )}
              />
            )
          case 'ContentfulPageRichTextSection':
            return (
              <RichText
                key={m.id}
                richText={documentToReactComponents(m.richText.json, options)}
                table={m.table && m.table.tableData.length > 0 ? m.table.tableData : null}
              />
            )
          case 'ContentfulPageFetchHringtTilUtlanda':
            return (
              <Outwards
                key={m.id}
                sectionTitle={m.sectionTitle}
                sectionDescription={documentToReactComponents(
                  m.sectionDescription.json,
                  options
                )}
                inputLabel={m.inputLabel}
                inputPlaceholder={m.inputPlaceholder}
                location={location}
              />
            )
          case 'ContentfulPageFetchSambandidIUtlondum':
            return (
              <Abroad
                key={m.id}
                sectionTitle={m.sectionTitle}
                sectionDescription={documentToReactComponents(
                  m.sectionDescription.json,
                  options
                )}
                inputLabel={m.inputLabel}
                inputPlaceholder={m.inputPlaceholder}
                location={location}
                insideEESText={
                  m.insideEesText ? (
                    documentToReactComponents(m.insideEesText.json)
                  ) : (
                    <></>
                  )
                }
                outsideEESTextWithTravelPackage={
                  m.outsideEesText ? (
                    documentToReactComponents(m.outsideEesText.json)
                  ) : (
                    <></>
                  )
                }
                outsideEESTextWithoutTravelPackage={
                  m.outsideEesTextWithoutTravelPackage ? (
                    documentToReactComponents(
                      m.outsideEesTextWithoutTravelPackage.json
                    )
                  ) : (
                    <></>
                  )
                }
              />
            )
          case 'ContentfulPageFaq':
            return (
              <FAQ
                key={m.id}
                sectionTitle={m.sectionTitle}
                sectionDescription={documentToReactComponents(
                  m.sectionDescription.json,
                  options
                )}
                faqItems={m.faqItems}
              />
            )
          case 'ContentfulPagePantaSimkort':
            return (
              <SimCardForm
                headline={m.sectionTitle}
                text={documentToReactComponents(
                  m.sectionDescription.json,
                  options
                )}
                successHeadline={m.successHeadline}
                successText={m.successText.content[0].content[0].value}
              />
            )
          case 'ContentfulPageLandingPage':
            return (
              <LandingPage
                title={m.sectionTitle}
                description={m.sectionDescription.sectionDescription}
                imageUrl={m.heroImage.file.url}
                resultText={m.resultText.resultText}
                eventTitle={m.eventTitle}
                redirectUrl={m.redirectUrl}
                formElements={m.formElements}
                buttonLabel={m.buttonLabel}
              />
            )
          default:
            return <></>
        }
      })}
    </>
  )
}
