import React from 'react'
import './PacketItem.scss'

export default props => {
  return (
    <div className="packet_item_container col" style={{ borderColor: props.ui_color }}>
      <div className="packet_item_top_section" style={{ borderColor: props.ui_color }}>
        <div>
          <h5 className="packet_item_size" style={{ color: props.ui_color }}>
            {props.package_size}
            <span className="packet_item_size_type" style={{ color: props.ui_color }}>GB</span>
          </h5>
          <span className="packet_item_inside_ees" style={{ color: props.ui_color }}>Innan EES: <span style={{ color: props.ui_color }}>{props.ees_value} GB</span></span>
        </div>
      </div>
      <div className="packet_item_bottom_section">
        <div className="packet_item_prize_container">
          {getPacketsPrice(props)}
        </div>
      </div>
    </div>
  )
}

const getPacketsPrice = props => (
  <>
  {
    props.onOffer
      ? <>
        <span className="packet_item_prize oldPrice" style={{ color: props.ui_color }}>{props.oldAmount}</span>
        <span className="packet_item_prize" style={{ color: props.ui_color }}>{props.package_prize}</span>
        <div>
          <span style={{ color: props.ui_color }}>KR</span>
          <span style={{ color: props.ui_color }}>/ 30 dagar</span>
        </div>
      </>
      : <>
        <span className="packet_item_prize" style={{ color: props.ui_color }}>{props.package_prize}</span>
        <div>
          <span style={{ color: props.ui_color }}>KR</span>
          <span style={{ color: props.ui_color }}>/ 30 dagar</span>
        </div>
      </>
  }
  </>
)
