import React from 'react'
import Section from '../../shared/Section/Section'
import './RichText.scss'

export default ({ richText, table }) => {
  return (
    <Section full>
      <div className="rich_text_content rich_text_container">
        {richText}
      </div>

      {table && (
        <div className="table_container">
          <table>
            <thead>
              <tr>
                {table[0].map((cols, i) => {
                  return <th key={'head_col_' + i}>{cols}</th>
                })}
              </tr>
            </thead>
            <tbody>
              {table.map((rows, index) => {
                if (index > 0) {
                  return (
                    <tr className={'place_' + index} key={'row_' + index}>
                      {rows.map((cols, i) => {
                        return <td key={'col_' + i}>{cols}</td>
                      })}
                    </tr>
                  )
                }
              })}
            </tbody>
          </table>
        </div>
      )}
    </Section>
  )
}
