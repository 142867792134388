export const findMatchingCountry = (currentCountry, countriesArr) => {
  let filteredCountries
  if (currentCountry) {
    const letters = currentCountry.toUpperCase()
    filteredCountries = countriesArr.filter(country => {
      const EN = country.name.toUpperCase()
      const IS = country.name_is.toUpperCase()
      if (EN.includes(letters) || IS.includes(letters)) {
        return country
      }
    })
  } else {
    filteredCountries = []
  }
  return filteredCountries
}
