import React, { useState, useEffect } from 'react'
import DatePicker from 'react-date-picker'
import './SimCardForm.scss'
import Input from '../../shared/InputItem/Input'
import Button from '../../shared/Button/Button'
import BigText from '../../shared/BigText/BigText'
import Section from '../../shared/Section/Section'
import AppIcons from '../../shared/AppIcons/AppIcons'
import Layout from '../../shared/Layout/layout'
import Text from '../../shared/Text/Text'
import MonospacedText from '../../shared/MonospacedText/MonospacedText'
import Select from '../../shared/Select/Select'
import LazyloadContentfulImage from '../../utils/LazyloadContentfulImage'
import axios from 'axios'
import { navigate } from 'gatsby'
import { isMobile } from 'react-device-detect'
import ReCAPTCHA from 'react-google-recaptcha'
import Modal from 'react-modal'

const API_URL = 'https://api.sambandid.is/open/'
const SITE_KEY = '6LdTxzIaAAAAAEWDkH_Xpk9Vb86JCfCTvSAkFMmH'
Modal.setAppElement('#___gatsby')

const customStyles = {
  content: {
    background: '#000'
  },
}

const fieldValidation = {
  name: {
    validation: function (name, cb) {
      if (name.length < 3) {
        throw this.errorMessage
      } else {
        return true
      }
    },
    errorMessage:
      'Það tókst ekki að senda inn pöntun. Gleymdir þú kannski að setja inn nafnið þitt?',
  },
  address: {
    validation: function (address, cb) {
      if (address.length < 3) {
        throw this.errorMessage
      } else {
        return true
      }
    },
    errorMessage:
      'Það tókst ekki að senda inn pöntun. Gleymdir þú kannski að setja inn heimilisfang?',
  },
  zip: {
    validation: function (zip, cb) {
      if (zip.length !== 3) {
        throw this.errorMessage
      } else {
        return true
      }
    },
    errorMessage:
      'Það tókst ekki að senda inn pöntun. Gleymdir þú kannski að setja inn póstnúmer?',
  },
  city: {
    validation: function (city, cb) {
      if (city.length < 3) {
        throw this.errorMessage
      } else {
        return true
      }
    },
    errorMessage:
      'Það tókst ekki að senda inn pöntun. Gleymdir þú kannski að setja inn bæjarfélag?',
  },
  ssn: {
    validation: function (ssn, cb) {
      if (!ssn || ssn.length < 5) throw this.errorMessage
      if (ssn.substring(0, 2) > 31) throw this.companyErrorMessage // FYRIRTÆKJAKENNITALA
      if (typeof ssn !== 'undefined' && ssn != null && ssn.length > 0) {
        ssn = ssn
          .trim()
          .replace('-', '')
          .replace(' ', '')
        if (ssn.length !== 10) throw this.errorMessage
        const sSum =
          3 * parseInt(ssn.substr(0, 1)) +
          2 * parseInt(ssn.substr(1, 1)) +
          7 * parseInt(ssn.substr(2, 1)) +
          6 * parseInt(ssn.substr(3, 1)) +
          5 * parseInt(ssn.substr(4, 1)) +
          4 * parseInt(ssn.substr(5, 1)) +
          3 * parseInt(ssn.substr(6, 1)) +
          2 * parseInt(ssn.substr(7, 1))
        let modRes = sSum % 11
        if (modRes > 0) modRes = 11 - modRes
        if (modRes !== parseInt(ssn.substr(8, 1))) throw this.errorMessage
        if (ssn.substring(0, 2) > 31) throw this.errorMessage
        const century = parseInt(ssn.substr(9, 1))
        if (
          isNaN(century) ||
          (century !== 0 && century !== 9 && century !== 8)
        ) {
          throw this.errorMessage
        }
      }
      return true
    },
    errorMessage:
      'Það tókst ekki að senda inn pöntun. Gleymdir þú kannski að setja inn kennitölu?',
    companyErrorMessage:
      'Þú hefur sett inn fyrirtækjakennitölu, vinsamlegast settu inn þína kennitölu.',
  },
  phoneNumber: {
    validation: function (phonenumber, cb) {
      if (!phonenumber || phonenumber.length < 5) throw this.errorMessage
      if (
        typeof phonenumber !== 'undefined' &&
        phonenumber != null &&
        phonenumber.length > 0
      ) {
        phonenumber = phonenumber.trim()
        if (phonenumber.length !== 7) {
          throw this.errorMessage
        }
        if (
          phonenumber[0] === '6' ||
          phonenumber[0] === '7' ||
          phonenumber[0] === '8'
        ) {
          return true
        } else {
          throw this.errorMessage
        }
      }
      return true
    },
    errorMessage:
      'Það tókst ekki að senda inn pöntun. Gleymdir þú kannski að setja inn símanúmer?',
  },
  transferDate: {
    validation: function (transferDate) {
      if (transferDate.getDay() === 0 || transferDate.getDay() === 6) {
        throw this.errorMessage
      } else {
        return true
      }
    },
    errorMessage:
      'Dagsetning má ekki vera helgidagur.',
  },
  email: {
    validation: function (email) {
      if (email === '' || !(email.includes('@') && email.includes('.'))) {
        throw this.errorMessage
      } else {
        return true
      }
    },
    errorMessage:
      'Netfang vantar eða ekki gilt.'
  },
  quantity: {
    validation: function (quantity, cb) {
      return true
    },
    errorMessage:
      'Það tókst ekki að senda inn pöntun. Gleymdir þú kannski að velja magn?',
  },
  comment: {
    validation: function (quantity, cb) {
      return true
    },
    errorMessage: 'Það tókst ekki að senda inn pöntun.',
  },
}

export default ({ headline, text, successHeadline, successText }) => {
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [zip, setZip] = useState('')
  const [city, setCity] = useState('')
  const [ssn, setSSN] = useState('')
  const [phoneNumber, setphoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [transferDate, setTransferDate] = useState(new Date())
  const [quantity, setQuantity] = useState(1)
  const [comment, setComment] = useState('')
  const [error, setError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isSendingPost, setIsSendingPost] = useState(false)
  const [showMobileSuccess, setShowMobileSuccess] = useState(false)
  const [recaptchaChecked, setRecaptchaChecked] = useState(false)
  const [validObject, setValidObject] = useState({})
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [pinInput, setPinInput] = useState('')
  const [allowResend, setAllowResend] = useState(true)

  const onSubmit = e => {
    const simObj = {
      name: name || '',
      address: address || '',
      zip: zip || '',
      city: city || '',
      ssn: ssn || '',
      phoneNumber: phoneNumber || '',
      email: email || '',
      transferDate: transferDate || '',
      quantity: quantity || '',
      comment: comment || '',
    }

    checkValid(simObj, async validObj => {
      setValidObject(validObj)
      await getUserPinInput()
    })
  }

  const getUserPinInput = async () => {
    try {
      const req = await axios.post(API_URL + 'confirm/' + phoneNumber)
      if (req.status === 200) {
        setErrorMessage('')
        setModalIsOpen(true)
      } else {
        setErrorMessage('Ekki næst samband við vefþjóna okkar. Sendu okkur póst á sambandid@sambandid.is eða heyrðu í okkur á Facebook spjallinu og við skoðum málið.')
      }
      return req
    } catch (error) {
      setErrorMessage('Ekki næst samband við vefþjóna okkar. Sendu okkur póst á sambandid@sambandid.is eða heyrðu í okkur á Facebook spjallinu og við skoðum málið.')
    }
  }

  const sendOrder = async () => {
    try {
      await sendPostRequest(validObject)
      if (isMobile) {
        setShowMobileSuccess(true)
        setIsSendingPost(false)
      } else {
        navigate('/', {
          state: {
            showNotification: {
              headline: successHeadline,
              text: successText,
            },
          },
          replace: true,
        })
        setIsSendingPost(false)
      }
    } catch (e) {
      setTimeout(() => {
        setIsSendingPost(false)
        setError('')
        setErrorMessage(
          'Úps! Eitthvað fór úrskeiðis. Reyndu aftur eftir nokkrar mínútur.'
        )
      }, 1500)
    }
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const confirmPin = async () => {
    try {
      const req = await axios.post(API_URL + 'confirm/' + phoneNumber + '/' + pinInput)
      if (req.status === 200) {
        await sendOrder()
        setModalIsOpen(false)
        return req
      } else {
        setErrorMessage(
          'Ekki hefur tekist að staðfesta pöntun með réttum kóða. Sendu okkur póst á sambandid@sambandid.is eða heyrðu í okkur á Facebook spjallinu og við skoðum málið.'
        )
      }
    } catch (error) {
      setErrorMessage(
        'Ekki hefur tekist að staðfesta pöntun með réttum kóða. Sendu okkur póst á sambandid@sambandid.is eða heyrðu í okkur á Facebook spjallinu og við skoðum málið.'
      )
    }
  }

  const checkValid = async (obj, cb) => {
    let hasError = false
    for (const property in obj) {
      if (hasError) {
        break
      }
      try {
        await fieldValidation[property].validation(obj[property])
        if (error) {
          setError('')
        }
      } catch (err) {
        setError(property)
        setErrorMessage(err)
        hasError = true
      }
    }

    if (!hasError) {
      return cb(obj)
    }
  }

  const handleRecaptcha = value => {
    setRecaptchaChecked(true)
  }

  const recaptchaExpired = () => {
    setRecaptchaChecked(false)
  }

  const sendPostRequest = async obj => {
    setIsSendingPost(true)
    const req = await axios.post(API_URL + 'order/sim', obj)
    return req
  }

  const resendPin = async () => {
    await getUserPinInput()
    setErrorMessage('Nýr staðfestingarkóði sendur á símanúmer: ' + phoneNumber)
    setAllowResend(false)
    setTimeout(() => {
      setAllowResend(true)
      setErrorMessage('')
    }, 10000)
  }

  const minDate = () => {
    const today = new Date()
    // minimum date of service transfer is 14 days AND the day of transfer cannot be on weekends
    const minDate = new Date(today.getTime() + (24 * 60 * 60 * 1000) * 14)
    if (minDate.getDay() === 0) {
      // sunday, add 1 day
      return new Date(minDate.getTime() + (24 * 60 * 60 * 1000) * 1)
    } else if (minDate.getDay() === 6) {
      // saturday, add 2 days
      return new Date(minDate.getTime() + (24 * 60 * 60 * 1000) * 2)
    } else {
      return minDate
    }
  }

  if (showMobileSuccess) {
    return <MobileSuccessScreen headline={successHeadline} text={successText} />
  }

  return (
    <></>
  )
}

const IMAGE_URL =
  '//images.ctfassets.net/0bvn2v2wiua3/2CKj5Wwizdcn8zBM2PYC3Q/2f5c449ef201d9c13bb3cc4ce2f98449/9._Bj__da_vini_-_gefa_gagnamagn_success.jpg'
const MobileSuccessScreen = ({ headline, text }) => {
  useEffect(() => {
    document.body.classList.toggle('no-scroll')

    return () => {
      document.body.classList.toggle('no-scroll')
    }
  }, [])
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: '#000',
        zIndex: 10000,
      }}
    >
      <LazyloadContentfulImage
        propStyles={{ height: '100%' }}
        imageUrl={IMAGE_URL}
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '120%',
          width: '100%',
          backgroundColor: 'rgba(0,0,0,0.63)',
          zIndex: 0,
        }}
      />
      <div
        style={{
          overflowY: 'scroll',
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <div
          style={{
            paddingBottom: '15px',
            position: 'absolute',
            bottom: 70,
            left: 0,
          }}
        >
          <Layout>
            <div style={{ marginBottom: '60px' }}>
              <Text
                style={{
                  color: '#fff',
                  fontWeight: '500',
                  marginBottom: '10px',
                  fontSize: '2.1em',
                }}
                text={headline}
              />
              <Text
                style={{
                  color: 'rgb(212, 212, 212)',
                  fontWeight: '500',
                  fontSize: '1.03em',
                  marginBottom: '10px',
                }}
                text={text}
              />
              <AppIcons />
            </div>
            <div
              role="button"
              onKeyDown={() => navigate('/')}
              tabIndex={'0'}
              onClick={() => navigate('/')}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <MonospacedText
                className="span_101"
                text={`FARA Á FORSÍÐU`}
                eStyles={{
                  fontWeight: '500',
                  lineHeight: 1,
                  cursor: 'pointer',
                  color: '#fff',
                  paddingTop: '10px',
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  marginBottom: '5px',
                }}
              />
            </div>
          </Layout>
        </div>
      </div>
    </div>
  )
}
