import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import './BackgroundVideoWithText.scss'
import Section from '../../shared/Section/Section'
import BigText from '../../shared/BigText/BigText'

export default ({ videoUrl, sectionTitle, sectionDescription }) => (
  <div style={{position:'relative'}}>
    <div style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 0}} />
    <div className="bluffer">
      <video
        className="showreel_video"
        autoPlay muted playsInline
        // poster={this.props.videoPoster}
      >
        <source
          src={videoUrl}
          type="video/mp4"
        />
      </video>
    </div>
    <Section centered full>
      <div className="video_text_container">
        <BigText text={sectionTitle} />
        <div className="rich_text_container">
          {sectionDescription}
        </div>
      </div>
    </Section>
  </div>
)
