import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import './imageRichText.scss'
import Section from '../../shared/Section/Section'
import BigText from '../../shared/BigText/BigText'

const ImageRichText = ({ sectionTitle, sectionDescription, showAppImage, appImage }) => (
  <Section>
    <div className={`${showAppImage ? 'row d-flex justify-content-center content_container flex-column-reverse flex-md-row' : 'content_container'}`}>
      {
      showAppImage && <div className="image_container col-sm-4">
        <img className="img-fluid" src={appImage} alt=""/>
      </div>
      }
      <div className={`text_container ${showAppImage ? 'col-sm-8' : ''}`}>
        <BigText text={sectionTitle} />
        <div className="rich_text_container">
          {sectionDescription}
        </div>
      </div>
    </div>
  </Section>
)

export default ImageRichText
