import React from 'react'
import Section from '../../shared/Section/Section'
import BigText from '../../shared/BigText/BigText'
import Text from '../../shared/Text/Text'
import Slider from '../../shared/Slider/Slider'
import CardItem from '../../shared/Card/CardItem'
import { StaticQuery, graphql } from 'gatsby'
import { slugify } from '../../utils/slugify'

export default ({ sectionTitle, sectionDescription }) => (
  <StaticQuery
    query={graphql`
    query {
      allEvents(limit: 5) {
        edges {
          node {
            Description
            DescriptionEnglish
            EventGroupId
            EventImagePath
            FeaturedImagePath
            Name
            NameEnglish
            PurchaseUrl
            PurchaseUrlEnglish
            SubTitle
            SubTitleEnglish
            id
          }
        }
      }
    }    
    `}
    render={data => {
      if (data.allEvents.edges.length === 0) return <></>

      return (
        <Section small>
          <BigText text={sectionTitle} />
          <div className="rich_text_container">
            {sectionDescription}
          </div>
          <Slider>
            {
              data.allEvents.edges.map((eventItem, index) => <CardItem key={eventItem.node.id} linkTo={`/vidburdir/${slugify(eventItem.node.Name)}`} square imgUrl={eventItem.node.EventImagePath} subheading="tónleikar" heading={eventItem.node.Name} />)
            }
          </Slider>
        </Section>
      )
    }}
  />
)
