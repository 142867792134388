import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Section from '../../shared/Section/Section'
import BigText from '../../shared/BigText/BigText'
import Text from '../../shared/Text/Text'
import Slider from '../../shared/Slider/Slider'
import PacketItem from '../../shared/PacketItem/PacketItem'

export default ({ sectionTitle, sectionDescription }) => (
  <StaticQuery
    query={graphql`
    query {
      allPackages(filter: {refillType: {eq: "DATA"}}) { 
        edges {
          node {
            amount
            amountWithSeperator
            oldAmountWithSeperator
            refillType
            refillTypeName
            validFor
            packageId
            uiPriceColor
            onOffer
            name
            id
            eesData
            description
            data
          }
        }
      }
    }    
    `}
    render={data => {
      return (
        <Section small>
          <BigText text={sectionTitle} />
          <div className="rich_text_container">
            {sectionDescription}
          </div>
          <Slider>
            {
              data.allPackages.edges.map((packetsItem, i) => {
                const packetsItemProps = {
                  package_size: packetsItem.node.data,
                  ees_value: packetsItem.node.eesData,
                  package_prize: packetsItem.node.amountWithSeperator,
                  onOffer: packetsItem.node.onOffer,
                  oldAmount: packetsItem.node.oldAmountWithSeperator,
                  ui_color: packetsItem.node.uiPriceColor,
                  key: packetsItem.node.id
                }
                return <PacketItem {...packetsItemProps} />
              })
            }
          </Slider>
        </Section>
      )
    }}
  />
)
