import React from 'react'
import BigText from '../../shared/BigText/BigText'
import Links from '../../shared/Links/Links'
import Section from '../../shared/Section/Section'

export default function LandingPageSuccess ({ resultText, redirectUrl }) {
  return (
    <Section centered>
      <BigText text={resultText} />
      <Links
        linkTo={redirectUrl}
        linkText={'Fara til baka á forsíðu'}
        isExternal={true}
      />
    </Section>
  )
}
