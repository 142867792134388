import React, { useState, useEffect } from 'react'
import InputItem from '../../shared/InputItem/Input'
import BigText from '../../shared/BigText/BigText'
import Text from '../../shared/Text/Text'
import SemiText from '../../shared/SemiText/SemiText'
import './Outwards.scss'
import { FilteredCountryItem } from '../../shared/FilteredCountryItem/FilteredCountryItem'
import { findMatchingCountry } from './findMatchingCountry'

const Outwards = props => {
  const [inputVal, setInputVal] = useState('')
  const [filteredCountries, setFilteredCountries] = useState([])
  const [activeCountry, setActiveCountry] = useState(null)
  const { countries } = props

  let timeout = null

  useEffect(() => {
    if (inputVal.length === 0) {
      setFilteredCountries([])
    }

    if (countries && !(inputVal.length === 0)) {
      timeout = setTimeout(() => {
        const filteredNations = findMatchingCountry(inputVal, countries)
        setFilteredCountries(filteredNations)
      }, 1000)
    }

    return () => clearTimeout(timeout)
  }, [inputVal, setInputVal])

  useEffect(() => {
    // ?country=belgium
    const urlParams = new URLSearchParams(props.location.search)
    const paramCountry = urlParams.get('country')
    if (paramCountry) {
      const filteredNations = findMatchingCountry(paramCountry, countries)
      if (filteredNations.length === 1) {
        setActiveCountry(filteredNations[0])
      }
    }
  }, [])

  const onKeyUp = e => {
    e.preventDefault()
    setActiveCountry(null)
    setInputVal(e.target.value)
  }

  return (
    <div className="outwards_container">
      <BigText text={props.sectionTitle} />
      <div className="rich_text_container">
        {props.sectionDescription}
      </div>
      <form onSubmit={e => e.preventDefault()} autoComplete="off">
        <InputItem value={activeCountry ? activeCountry.country.IcelandicName : inputVal} labelText={props.inputLabel} placeholder={props.inputPlaceholder} uniqueId="hringtTilUtlanda" onChange={onKeyUp}/>
      </form>
      {
        activeCountry
          ? null
          : <div className="" style={{ maxHeight: '450px', overflowY: 'scroll' }}>
            { filteredCountries.map((item, index) => <FilteredCountryItem index={index} onClick={() => setActiveCountry(item)} key={item.country.id} text={`${item.country.IcelandicName} (${item.country.EnglishName})`} />) }
          </div>
      }
      <div>
        {
          activeCountry
            ? <div style={{ marginTop: '30px' }}>
              <SemiText text="Almenn verðskrá" />
              <ul className="outwards_price_list_container">
                <li className="outwards_price_list_item">
                  <span>Upphafsgjald</span>
                  <span>{activeCountry.charges.Mobile.StartFeeMobile} kr.</span>
                </li>
                <li className="outwards_price_list_item">
                  <span>Mínútan í heimasíma</span>
                  <span>{activeCountry.charges.Mobile.MobileToLandline} kr.</span>
                </li>
                <li className="outwards_price_list_item">
                  <span>Mínútan í farsíma</span>
                  <span>{activeCountry.charges.Mobile.MobileToMobile} kr.</span>
                </li>
                <li className="outwards_price_list_item">
                  <span>SMS</span>
                  <span>{activeCountry.charges.Mobile.SMSCharge} kr.</span>
                </li>
                <li className="outwards_price_list_item">
                  <span>Myndskilaboð</span>
                  <span>{activeCountry.charges.Mobile.MMSCharge} kr.</span>
                </li>
              </ul>
            </div>
            : null
        }
      </div>
    </div>
  )
}

export default Outwards
