import React, { useState, useEffect } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import s from './BackgroundImageWithText.scss'
import Section from '../../shared/Section/Section'
import BigText from '../../shared/BigText/BigText'
import Text from '../../shared/Text/Text'

const BackgroundImageWithText = ({ desktopBackgroundImage, mobileBackgroundImage, sectionTitle, sectionDescription, showAppImage }) => {
  const [isMobile, setMobile] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if ((window.innerWidth < window.innerHeight)) {
        setMobile(true)
      }
    }
  }, [])

  return (
  <Section backgroundImage={isMobile ? mobileBackgroundImage : desktopBackgroundImage }>
    <div style={{position: 'absolute', top: 0, left: 0, height: '120%', width: '100%', backgroundColor: 'rgba(0,0,0,0.73)', zIndex: 0}} />
    <div className="row d-flex justify-content-center flex-column-reverse flex-md-row">
      <div className="image_container col-sm-4 section_background">
        {
          showAppImage && <img className="img-fluid" src={'/phone.png'} alt=""/>
        }
      </div>
      <div className="text_container col-sm-8">
        <BigText text={sectionTitle} />
        <div className="rich_text_container">
          {sectionDescription}
        </div>
      </div>
    </div>
  </Section>
)}

export default BackgroundImageWithText
