import React, { useState, useEffect } from 'react'
import { fetchCountries } from './fetchCountries'
import Abroad from './Abroad'
import Section from '../../shared/Section/Section'

export default props => {
  const [countries, setCountries] = useState(null)
  useEffect(() => {
    fetchCountries().then(res => setCountries(res.data))
  }, [])

  if (!countries) {
    return (<div style={{minHeight:'100vh'}}></div>)
  } else {
    return <Section full><Abroad countries={countries} {...props} /></Section>
  }
}
