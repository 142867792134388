import React from 'react'
import './Select.scss'

export default ({ children, uniqueId, labelText, onChange }) => (
  <>
    <label htmlFor={uniqueId} className="input_101_label">{labelText}</label>
    <select onBlur={onChange} id={uniqueId} className="select_101_item">
      {children}
    </select>
  </>
)
