import React, { useState, useEffect } from 'react'
import Section from '../../shared/Section/Section'
import BigText from '../../shared/BigText/BigText'
import Button from '../../shared/Button/Button'
import Text from '../../shared/Text/Text'
import axios from 'axios'
import './LandingPage.scss'
import LandingPageForm from './LandingPageForm'
import LandingPageSuccess from './LandingPageSuccess'

export default ({
  title,
  imageUrl,
  description,
  resultText,
  eventTitle,
  redirectUrl,
  formElements,
  buttonLabel,
}) => {
  const [error, setError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showResult, setShowResult] = useState(false)
  const [loading, setLoading] = useState(false)
  const [model, setModel] = useState(null)
  const [validationModel, setValidationModel] = useState(null)

  const API_URL = 'https://api.sambandid.is/open/landingpage/event'

  useEffect(() => {
    const m = new Map()
    const v = new Map()
    for (const el of formElements) {
      if (el.type === 'empty') {
        continue
      } else if (el.type === 'checkbox') {
        m.set(el.parameter, false)
      } else {
        m.set(el.parameter, null)
      }
      if (el.validateField) {
        const regex = el.regexValidation ? new RegExp(el.regexValidation) : null
        v.set(el.parameter, {
          validation: (value, cb) => {
            if (value === null) {
              throw throwError(el.validationErrorMessage, el.label)
            }
            if (typeof value === 'boolean') {
              value = value.toString()
            }
            value = value.trim()
            if (el.minLength) {
              if (value.length < el.minLength) {
                throw throwError(el.validationErrorMessage, el.parameter)
              }
            }
            if (el.maxLength) {
              if (value.length > el.maxLength) {
                throw throwError(el.validationErrorMessage, el.parameter)
              }
            }
            if (regex) {
              if (regex && regex.test(value)) {
                return true
              }
              throw throwError(el.validationErrorMessage, el.parameter)
            }
            return true
          },
        })
      } else {
        v.set(el.parameter, { validation: (value, cb) => true })
      }
    }
    setModel(m)
    setValidationModel(v)
  }, [])

  const onSubmit = async () => {
    await checkValid(model, async validModel => {
      try {
        setErrorMessage('')
        setError('')
        setLoading(true)
        const modelObj = {}
        for (const [key, value] of validModel) {
          modelObj[key] = value
        }
        const req = { event: eventTitle, eventLead: modelObj }
        await sendPostRequest(req)
        setLoading(false)
        setShowResult(true)
      } catch (e) {
        setTimeout(() => {
          setError('')
          setLoading(false)
          setErrorMessage(
            'Úps! Eitthvað fór úrskeiðis. Reyndu aftur eftir nokkrar mínútur.'
          )
        }, 5000)
      }
    })
  }

  const checkValid = async (model, cb) => {
    let hasError = false
    for (const [key, value] of model) {
      if (hasError) {
        break
      }
      try {
        const v = validationModel.get(key)
        if (v) {
          await v.validation(value)
        }
      } catch (err) {
        console.log(err)
        setError(key)
        setErrorMessage(err)
        hasError = true
      }
    }
    if (!hasError) {
      return cb(model)
    }
  }

  const sendPostRequest = async obj => {
    console.log('send post request', obj)
    const res = await axios.post(API_URL, obj, { timeout: 5000 })
    return res
  }

  const handleModelChange = (label, value) => {
    const temp = model
    temp.set(label, value)
    setModel(temp)
  }

  const throwError = (errorMessage, label) =>
    errorMessage || `Ó nei, ${label} er ekki á réttu formi`

  return (
    <>
      {!showResult && (
        <div>
          <div className="hero_container">
            <img src={imageUrl} alt={title} />
            <div className="hero_container_fade">
              <div className="hero_content">
                <BigText text={title} />
                <p>{description}</p>
              </div>
            </div>
          </div>
          <Section small>
            <div className="sim_card_form_container">
              <form className="sim_card_form" onSubmit={onSubmit}>
                <LandingPageForm
                  model={model}
                  handleModelChange={handleModelChange}
                  formElements={formElements}
                  error={error}
                />
                <Button
                  disabled={loading}
                  type="button"
                  text={buttonLabel || 'Staðfesta'}
                  onClick={onSubmit}
                />
                <Text
                  style={{ marginTop: '15px', color: '#f00' }}
                  text={errorMessage}
                />
              </form>
            </div>
          </Section>
        </div>
      )}
      {showResult && (
        <LandingPageSuccess resultText={resultText} redirectUrl={redirectUrl} />
      )}
    </>
  )
}
