import React, { useState } from 'react'
import SemiText from '../../shared/SemiText/SemiText'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Text from '../../shared/Text/Text'
import s from './FAQ.scss'

export default ({ item }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <div role="button" tabIndex="0" onKeyPress={() => setOpen(!isOpen)} onClick={() => setOpen(!isOpen)} className="faq_item_container" >
      <div className="">
        {
          isOpen
            ? <img src={'/minus.svg'} alt="minus icon" />
            : <img src={'/plus.svg'} alt="minus icon" />
        }
      </div>
      <div style={{ marginLeft: '40px' }}>
        <SemiText text={item.faqQuestion} />
        <div style={{ marginTop: '12px', display: isOpen ? 'block' : 'none' }}>
          <div className="rich_text_container">
            {documentToReactComponents(item.faqAnswer.json)}
          </div>
        </div>
      </div>
    </div>
  )
}
