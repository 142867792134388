import React from 'react'
import Input from '../../shared/InputItem/Input'
import Select from '../../shared/Select/Select'

export default function LandingPageForm ({
  formElements,
  error,
  model,
  handleModelChange,
}) {
  return (
    <div className="sim_card_form_flex">
      {formElements &&
        formElements.length > 0 &&
        formElements.map((el, i) => {
          if (el.type === 'input') {
            return (
              <div className="sim_card_form_input_container">
                <Input
                  type="text"
                  required
                  minLength={el.minLength}
                  maxLength={el.maxLength}
                  hasError={error === el.parameter}
                  labelText={el.label}
                  onChange={e =>
                    handleModelChange(el.parameter, e.target.value)
                  }
                  placeholder={el.placeholder}
                  uniqueId={`${el.parameter}${i}Input`}
                />
              </div>
            )
          }
          if (el.type === 'checkbox') {
            return (
              <div className="sim_card_form_input_container">
                <div>
                  <label className="checkboxContainer">
                    <input
                      onChange={() => {
                        const checked = !model.get(el.parameter)
                        handleModelChange(el.parameter, checked)
                      }}
                      type="checkbox"
                      defaultValue={(model && model.get(el.parameter)) || false}
                    />
                    <span className="checkmark"></span>
                    {el.label}
                  </label>
                </div>
              </div>
            )
          }
          if (el.type === 'dropdown') {
            return (
              <div className="sim_card_form_input_container">
                <Select
                  className="quantity_select"
                  onChange={e =>
                    handleModelChange(el.parameter, e.target.value)
                  }
                  uniqueId={`${el.parameter}${i}Dropdown`}
                  labelText={el.label}
                >
                  {el.options.map((o, i) => (
                    <option key={`${o}${i}`}>{o}</option>
                  ))}
                </Select>
              </div>
            )
          }
          if (el.type === 'empty') {
            return <div className="sim_card_form_input_container"></div>
          }
        })}
    </div>
  )
}
